import React from 'react';
import '../css/ProductHeader.scss';
import ProductImage from 'app/components/customer/steps/Payment/king/ProductImage';
import subProductNames from 'app/utils/subProductNames';

type Props = {
  subProductName: string;
  productName: string;
  breadcrumbs?: JSX.Element | null;
  showTitle?: boolean;
  cardStyle?: boolean;
};

const ProductHeader = ({
  productName,
  subProductName,
  breadcrumbs = null,
  showTitle = false,
  cardStyle = false,
}: Props) => (
  <div className={`${cardStyle ? 'payment_card' : ''} ${showTitle ? 'mb24' : ''} product-header-container`}>
    {breadcrumbs && <div className="breadcrumbs">{breadcrumbs}</div>}
    <ProductImage productName={productName} subProductName={subProductName} className={showTitle ? 'with-title' : ''} />
    {showTitle && <h4 className="subtitle">{subProductNames[productName][subProductName]}</h4>}
  </div>
);

export default ProductHeader;
