import React from 'react';
import { P, Title } from 'app/components/common/Typography';
import { AvailableProducts, LabKitTypes } from 'app/constants/Products';
import { SecondaryButton } from 'app/components/common/Button';
import Divider from '@setproduct-ui/core/Divider';
import { apiRequestUserCommand } from 'app/actions/customer';
import { useAppDispatch } from 'app/helpers/hooks';
import { push } from 'connected-react-router/immutable';
import { selectFurthestProductStepPath } from 'app/selectors/customer';

type Props = {
  setFieldValue: any;
  setOpenModalAtHomeLab: any;
  kingV2OralTrt?: boolean;
};
const KingLabWidget = ({ setFieldValue, setOpenModalAtHomeLab }: Props) => {
  const dispatch = useAppDispatch();

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, AvailableProducts.King));

  const handleOwnLabClick = () => {
    setFieldValue('lab_kit_type', LabKitTypes.UseYourOwnLab);
    setFieldValue('use_own_lab', true);

    const action = apiRequestUserCommand({
      cmdType: 'lab_selection_opt_in',
      params: {
        lab_kit_type: LabKitTypes.UseYourOwnLab,
        product_name: AvailableProducts.King,
        use_own_lab: true,
      },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  return (
    <>
      <>
        <P className="mb12 lab_step_paragraph_format">
          Lab testing is required after checkout in the first two months of treatment. The first to assess your hormone
          levels before treatment, and the second to track your progress after treatment.
        </P>
        <div className="mt24 mb12">
          <Title className="mb16" size="l">
            At-Home Lab Kits
          </Title>
        </div>
        <div className="display_flex">
          <P className="mb12 lab_step_paragraph_format">
            Maximus uses CLIA-certified lab tests that ship straight to your door. The tests are painless and do not
            require finger-pricking. Results are available FAST (typically within four business days).{' '}
            <a href="#" onClick={() => setOpenModalAtHomeLab(LabKitTypes.AtHomeLabKit)}>
              Full details
            </a>
          </P>
        </div>
      </>
      {/*{kingV2OralTrt && (*/}
      {/*  <>*/}
      {/*    <P className="mb12 lab_step_paragraph_format">*/}
      {/*      Lab testing is required after checkout in the first three months of treatment. The first to access your*/}
      {/*      hormone levels before treatment and the second two to track your progress after treatment.*/}
      {/*    </P>*/}
      {/*    <div className="price_row mt24 mb12">*/}
      {/*      <Title className="mb16" size="l">*/}
      {/*        Walk-in <span className="desc">(Quest Labs)</span>*/}
      {/*      </Title>*/}
      {/*    </div>*/}
      {/*    <div className="display_flex">*/}
      {/*      <P className="mb12 lab_step_paragraph_format color_gray_3">*/}
      {/*        We’ve partnered with Quest Labs to make it easy. We’ll provide you with a pre-paid requisition form that*/}
      {/*        you can take to your nearest Quest Lab at your convenience.{' '}*/}
      {/*        <a href="#" onClick={() => setOpenModalAtHomeLab(LabKitTypes.WalkIn)}>*/}
      {/*          Full details*/}
      {/*        </a>*/}
      {/*      </P>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
      <>
        <Divider className="color-gray mt16 mb4" />
        <Title className="center-align mb16 mt24" size="l">
          Already have Labs?
        </Title>
        <P className="mb12 mt4 lab_step_paragraph_format color_gray_3">
          If you have lab work completed within the past 6 months and meet the clinical requirements, you may use your
          own lab results instead.{' '}
        </P>
        <SecondaryButton
          className="mt24 mb4"
          text="USE OWN LABS"
          onClick={handleOwnLabClick}
          data-testid="use-own-lab"
        />
      </>
    </>
  );
};

export default KingLabWidget;
