/* eslint-disable no-case-declarations */
import { LabKitTypes, KingV2SubProducts } from 'app/constants/Products';
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';

const KING_ENCLO_PREG_NEW_MONTHLY_PRICING = {
  1: 199_99,
  3: 149_99,
  12: 99_99,
};

const KING_ENCLO_PREG_BB_NEW_PRICING = {
  1: 49_99,
  3: 44_99,
  12: 34_99,
};

// Key is a number of months
const KING_SUBSCRIPTION_COST_NO_BB = {
  [KingV2SubProducts.EncloPregnolone]: {
    1: 199_99,
    3: 479_97,
    12: 1439_88,
  },
  [KingV2SubProducts.Trt]: {
    1: 249_99,
    3: 629_97,
    12: 2039_88,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 299_99,
    3: 779_97,
    12: 2639_88,
  },
};

// Key is a number of months
const KING_SUBSCRIPTION_COST_BB_ON_ANOTHER_PROTOCOL = {
  [KingV2SubProducts.EncloPregnolone]: {
    1: 199_99,
    3: 449_97,
    12: 119_988,
  },
  [KingV2SubProducts.Trt]: {
    1: 249_99,
    3: 599_97,
    12: 1799_88,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 299_99,
    3: 749_97,
    12: 2399_00,
  },
};

const KING_SUBSCRIPTION_COST_BB_INCLUDED = {
  [KingV2SubProducts.EncloPregnolone]: {
    1: 249_98,
    3: 599_94,
    12: 1799_76,
  },
  [KingV2SubProducts.Trt]: {
    1: 299_98,
    3: 749_94,
    12: 2399_76,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 349_98,
    3: 899_94,
    12: 2999_76,
  },
};

export const KING_MONTHLY_PRICE_NO_BB = {
  [KingV2SubProducts.EncloPregnolone]: {
    1: 199_99,
    3: 159_99,
    12: 119_99,
  },
  [KingV2SubProducts.Trt]: {
    1: 249_99,
    3: 209_99,
    12: 169_99,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 299_99,
    3: 259_99,
    12: 219_99,
  },
};

export const KING_MONTHLY_PRICE_BB_INCLUDED = {
  [KingV2SubProducts.EncloPregnolone]: {
    1: 199_99,
    3: 149_99,
    12: 99_99,
  },
  [KingV2SubProducts.Trt]: {
    1: 249_99,
    3: 199_99,
    12: 149_99,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 299_99,
    3: 249_99,
    12: 199_99,
  },
};

const LAB_PRICE_BY_INTAKE_NUMBER = {
  1: 149_99,
  2: 99_99,
  3: 149_99,
};

class KingPriceCalculator extends BasePriceCalculator {
  selected_king_v2_product: KingV2SubProducts;
  isOnboarding: boolean;
  oralTrtLabNumber?: 1 | 2 | 3 | null;
  crlFeatureEnabledEncloPregWeightLoss: boolean;
  labKitType: LabKitTypes;
  encloPregNewPricing: boolean;
  productChangedtoOralTrt: boolean;

  KING_LAB_COST = 99_99;
  KING_WALK_IN_LAB_COST = 109_99;
  LEGACY_KING_LAB_COST = 99_99;
  APPOINTMENT_PRICE = 20_00;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    selected_king_v2_product: KingV2SubProducts,
    multiMonthPlan: 1 | 3 | 12,
    useOwnLab: boolean,
    intakeName: string,
    oral_trt_lab_number: null | 1 | 2 | 3 = null,
    crlFeatureEnabledEncloPregWeightLoss = false,
    labKitType: LabKitTypes = LabKitTypes.AtHomeLabKit,
    isOnboarding = false,
    subscriptionPaid = false,
    labPaid = false,
    encloPregNewPricing = false,
    productChangedtoOralTrt = false,
  ) {
    super(includeBB, includeBBonAnotherProtocol, intakeName, multiMonthPlan, useOwnLab, subscriptionPaid, labPaid);

    // This is specifically for when an Admin updates the User's
    //  Enclomiphene Version to Enclo Only before they check out
    if (selected_king_v2_product === KingV2SubProducts.Enclo) {
      this.selected_king_v2_product = KingV2SubProducts.EncloPregnolone;
    } else {
      this.selected_king_v2_product = selected_king_v2_product;
    }

    this.labKitType = labKitType;
    this.oralTrtLabNumber = oral_trt_lab_number;
    this.crlFeatureEnabledEncloPregWeightLoss = crlFeatureEnabledEncloPregWeightLoss;
    this.isOnboarding = isOnboarding;
    this.encloPregNewPricing = encloPregNewPricing;
    this.productChangedtoOralTrt = productChangedtoOralTrt;
  }

  // Interface
  monthlyCost() {
    if (this.encloPregNewPricing && this.selected_king_v2_product === KingV2SubProducts.EncloPregnolone) {
      return KING_ENCLO_PREG_NEW_MONTHLY_PRICING[this.multiMonthPlan];
    }

    if (this.includeBB || this.includeBBonAnotherProtocol) {
      return KING_MONTHLY_PRICE_BB_INCLUDED[this.selected_king_v2_product][this.multiMonthPlan];
    }
    return KING_MONTHLY_PRICE_NO_BB[this.selected_king_v2_product][this.multiMonthPlan];
  }

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    if (!this.multiMonthPlan) {
      return 199_00;
    }

    if (this.encloPregNewPricing && this.selected_king_v2_product === KingV2SubProducts.EncloPregnolone) {
      const bbMonthlyPrice = this.includeBB ? KING_ENCLO_PREG_BB_NEW_PRICING[this.multiMonthPlan] : 0;
      return (KING_ENCLO_PREG_NEW_MONTHLY_PRICING[this.multiMonthPlan] + bbMonthlyPrice) * this.multiMonthPlan;
    }

    if (this.includeBB) {
      return KING_SUBSCRIPTION_COST_BB_INCLUDED[this.selected_king_v2_product][this.multiMonthPlan];
    } else if (this.includeBBonAnotherProtocol) {
      return KING_SUBSCRIPTION_COST_BB_ON_ANOTHER_PROTOCOL[this.selected_king_v2_product][this.multiMonthPlan];
    }

    return KING_SUBSCRIPTION_COST_NO_BB[this.selected_king_v2_product][this.multiMonthPlan];
  }

  appointmentCost() {
    return this.oralTrt() && (this.isOnboarding || this.productChangedtoOralTrt) ? this.APPOINTMENT_PRICE : 0;
  }

  atHomeLabPrice() {
    if (this.oralTrt()) {
      const monthNumber = this.oralTrtLabNumber || 1;
      return LAB_PRICE_BY_INTAKE_NUMBER[monthNumber];
    } else if (this.crlFeatureEnabledEncloPregWeightLoss) {
      return this.KING_LAB_COST;
    } else {
      return this.LEGACY_KING_LAB_COST;
    }
  }

  labCost() {
    if (this.labPaid || this.useOwnLab || (!this.isOnboarding && !this.productChangedtoOralTrt)) return 0;

    return this.labKitType === LabKitTypes.WalkIn ? this.KING_WALK_IN_LAB_COST : this.atHomeLabPrice();
  }

  totalDueTodayForCurrentProduct = () => this.subscriptionCost() + this.labCost() + this.appointmentCost();

  // Discounts
  bbDiscount() {
    if (this.encloPregNewPricing && this.selected_king_v2_product === KingV2SubProducts.EncloPregnolone) {
      return 0;
    }

    if (!(this.includeBB || this.includeBBonAnotherProtocol) || !this.multiMonthPlan || this.multiMonthPlan < 3) {
      return 0;
    }

    return this.bbPotentialDiscount();
  }

  bbPotentialDiscount() {
    if (this.encloPregNewPricing && this.selected_king_v2_product === KingV2SubProducts.EncloPregnolone) {
      return this.BB_PRICE - this.bbPrice();
    }

    const map = {
      1: 0,
      3: 10_00,
      12: 20_00,
    };

    return map[this.multiMonthPlan || 1];
  }

  nonDiscountedPrice() {
    if (!this.bbDiscount()) {
      return 0;
    }

    return this.monthlyCost() + this.bbDiscount();
  }

  // Private product specific methods
  oralTrt() {
    return (
      this.selected_king_v2_product === KingV2SubProducts.Trt ||
      this.selected_king_v2_product === KingV2SubProducts.EncloPregnoloneTrt
    );
  }

  bbPrice = () => {
    if (
      this.encloPregNewPricing &&
      this.selected_king_v2_product === KingV2SubProducts.EncloPregnolone &&
      !this.includeBBonAnotherProtocol
    ) {
      return KING_ENCLO_PREG_BB_NEW_PRICING[this.multiMonthPlan];
    }

    return this.BB_PRICE;
  };

  montlyPayment = () => {
    return KING_MONTHLY_PRICE_BB_INCLUDED[this.selected_king_v2_product][1];
  };

  quarterlyPayment = () => {
    return KING_MONTHLY_PRICE_BB_INCLUDED[this.selected_king_v2_product][3] * 3;
  };

  yearlyPayment = () => {
    return KING_MONTHLY_PRICE_BB_INCLUDED[this.selected_king_v2_product][12] * 12;
  };
}

export default KingPriceCalculator;
