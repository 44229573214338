import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from 'app/constants/Routes';

import LabKitImgSrc from 'images/congrats/lab-kit.jpg';
import QuestLabKitImgSrc from 'images/congrats/quest_lab.png';
import JoinImgSrc from 'images/congrats/join.jpg';
import EBookImgSrc from 'images/congrats/e-book.jpg';

import './css/Congrats.scss';
import { useLocation } from 'react-router-dom';
import protocolNames from 'app/utils/protocolNames';
import { KingV2SubProducts } from 'app/constants/Products';
import { useSelector } from 'react-redux';
import { selectCustomer } from '../../../selectors/customer';
import { LabKitTypes } from '../../../constants/Products';

const King = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const currentProductName = params.get('product');
  const kingV2OralTrt = [KingV2SubProducts.Trt, KingV2SubProducts.EncloPregnoloneTrt].includes(currentProductName);
  const customer = useSelector(selectCustomer);
  const use_own_lab = customer.get('use_own_lab');
  const lab_kit_type = customer.get('king_lab_kit_type');

  const atHomeLabBlock = (
    <>
      <div className="block-list__item">
        <p className="list-item__text">
          In order to determine which <b>treatment plan</b> is best for you, your doctor will need to evaluate your{' '}
          <b>baseline hormone levels</b>.
        </p>
        <h3 style={{ textAlign: 'left' }} className="congrats-block__title-list">
          At-Home Lab Kit is on the way...
        </h3>
        <div className="mb24">
          <img src={LabKitImgSrc} className="list-item__image" />
        </div>
        <div className="block-list__item">
          <p className="list-item__text">
            Expect an <b>at-home lab kit</b> to arrive at your door in{' '}
            <u>
              <i>2-7 business days</i>
            </u>
            . You will receive tracking information once the laboratory ships your kit.
          </p>
          <p className="list-item__text">
            Your lab kit will come with <b>step-by-step instructions</b> for how to collect your sample and when to
            expect your results.
          </p>
        </div>
      </div>
      <div className="block-list__item"></div>
    </>
  );

  const questLabBlock = (
    <>
      <div className="block-list__item">
        <p className="list-item__text">
          In order to determine which <b>treatment plan</b> is best for you, your doctor will need to evaluate your{' '}
          <b>baseline hormone levels</b>.
        </p>
        <h3 style={{ textAlign: 'left' }} className="congrats-block__title-list">
          Find your nearest Quest Labs
        </h3>
        <div className="mb24">
          <img src={QuestLabKitImgSrc} className="list-item__image" />
        </div>
        <div className="block-list__item">
          <p className="list-item__text">
            Set up a time to walk in to your{' '}
            <a href="https://www.questdiagnostics.com/locations/search" rel="noreferrer" target="_blank">
              nearest Quest Labs
            </a>{' '}
            within the next few days.
          </p>
          <p className="list-item__text">
            A requisition form for Quest Labs has been mailed to you. Simply print it out and bring it or have them scan
            the barcode from your mobile device.
          </p>
        </div>
      </div>
      <div className="block-list__item"></div>
    </>
  );

  const nextUpBlock = (
    <div className="congrats-block congrats-block_single">
      <h2 className="congrats-block__title">Next Up:</h2>
      <h3 style={{ textAlign: 'left' }} className="congrats-block__title-list">
        Test Your Baseline Hormone Levels (Required)
      </h3>
      <div className="congrats-block__list block-list">
        {lab_kit_type === LabKitTypes.WalkIn ? questLabBlock : atHomeLabBlock}
      </div>
    </div>
  );

  return (
    <div className="congrats">
      <div className="congrats__wrap">
        <div className="congrats-top">
          <h1 className="congrats-top__title">You’re All Caught Up!</h1>
          <p className="congrats-top__info">That's all the information we need from you to get you started. </p>
        </div>

        {!use_own_lab && nextUpBlock}

        <div className="congrats-block">
          <h2 className="congrats-block__title">Meanwhile, Today You Can:</h2>
          <div className="congrats-block__list block-list">
            <div className="block-list__item">
              <h3 className="list-item__title">Join the Maximus Tribe and Start Coaching</h3>
              <img src={JoinImgSrc} className="list-item__image" />
              <p className="list-item__text">
                Join our community, <b>The Maximus Tribe</b>, by visiting:{' '}
                <a href="https://www.maximustribe.com/discord" target="_blank" rel="noreferrer">
                  maximustribe.com/discord
                </a>
              </p>
              <p className="list-item__text">
                Once there join the <i>#coaching</i> channel.
              </p>
              <p className="list-item__text">
                Introduce yourself to the group and share your goals. Then <b>post at least one health behavior</b>{' '}
                you’d like to work on such as nutrition, sleep, exercise, or focus.
              </p>
              <p className="list-item__text">
                You will be welcomed and coached by a <b>licensed psychologist</b>, alongside your band of brothers, The
                Maximus Tribe.
              </p>
            </div>
            <div className="block-list__item">
              <h3 className="list-item__title">Read the Maximus E-book</h3>
              <img src={EBookImgSrc} className="list-item__image" />
              <p className="list-item__text">
                Dr. Cam has written an E-book that explains Maximus’ <b>evidence-based recommendations</b>.
              </p>
              <p className="list-item__text">
                For example: how much sleep is optimal, and what kind of food you should eat to{' '}
                <b>maximize your testosterone</b>.
              </p>
              <p className="list-item__text">
                You can find the E-book at:{' '}
                <a href="https://www.maximustribe.com/book" target="_blank" rel="noreferrer">
                  maximustribe.com/book
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="congrats-dashboard">
          <p className="congrats-dashboard__text">
            You can access all of the above information at any time by viewing the{' '}
            {kingV2OralTrt ? (
              <a href="https://www.maximustribe.com/oral-trt-guide" target="_blank" rel="noreferrer">
                {protocolNames['king']} Protocol Quickstart Guide
              </a>
            ) : (
              <a href="https://www.maximustribe.com/guidetasso" target="_blank" rel="noreferrer">
                {protocolNames['king']} Protocol Quickstart Guide
              </a>
            )}
            .
          </p>
          <p className="congrats-dashboard__text">
            You can access the Quickstart Guide at any time by visiting the {protocolNames['king']} Protocol Dashboard.
          </p>
          <Link
            data-testid="go-to-dashboard"
            className="congrats-dashboard__link default-button primary-button"
            to={Routes.Dashboard}
          >
            GO TO DASHBOARD
          </Link>
        </div>

        {/*      <div className="congrats-block congrats-block_help">
        <div className="congrats-block__list block-list">
          <div className="block-list__item list-item">
            <h3 className="list-item__title">Do you need help? We’ve got your back.</h3>
            <p className="list-item__text">
              To help get the most out of the protocol, we encourage all new Maximizers to schedule a complimentary call
              with one of our Onboarding Specialists.
            </p>
            <p className="list-item__text">One of our team members will help guide you through:</p>
            <ul className="list-item__list">
              <li>Completing your at-home lab kit and meeting your doctor</li>
              <li>How medication refills work</li>
              <li>Ramping up with the community and coaching</li>
              <li>Any other questions you have</li>
            </ul>
            <p className="list-item__text">
              <a href="https://calendly.com/onboarding-maximus/10min" target="_blank" rel="noreferrer">
                <b>Click Here to Schedule</b>
              </a>
            </p>
          </div>
        </div>
      </div>*/}
      </div>
    </div>
  );
};

export default King;
