import { AvailableProducts, KingV2SubProducts, MagicianSubProducts } from 'app/constants/Products';

const subProductNames = {
  [AvailableProducts.King]: {
    [KingV2SubProducts.EncloPregnolone]: 'Enclomiphene + Pregnenolone',
    [KingV2SubProducts.Trt]: 'Oral TRT',
    [KingV2SubProducts.EncloPregnoloneTrt]: 'Oral TRT+',
  },
  [AvailableProducts.Magician]: {
    [MagicianSubProducts.Semaglutide]: 'Semaglutide',
    [MagicianSubProducts.Tirzepatide]: 'Tirzepatide',
  },
};

export default subProductNames;
