import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import React from 'react';
import PriceCalculator from 'app/helpers/priceCalculator';

type Props = {
  priceCalculator: PriceCalculator;
};
const GenericOldPrice = ({ priceCalculator }: Props) => (
  <div style={{ opacity: priceCalculator.nonDiscountedPrice() ? 1 : 0 }} className="price_row">
    <div>&nbsp;</div>
    <div>
      <PriceFormatter price={priceCalculator.nonDiscountedPrice()} className="payment_method__old_price" />
    </div>
  </div>
);

export default GenericOldPrice;
