import React, { useState } from 'react';

import { P } from 'app/components/common/Typography';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomerState } from 'app/selectors/customer';
import GenericProductSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericProductSelectionForm';
import { KingIntake } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';

const ModalBodySubscription = () => (
  <>
    <P className="bold">Enclomiphene + Pregnenolone (Sublingual Tablet)</P>
    <P className="mb12">
      Works for increasing testosterone in most men. If you are unsure which product you want to start with, you are
      encouraged to start with enclomiphene + pregnenolone to assess your response before taking oral testosterone
      replacement therapy (TRT). You may request to transition to one of the other products at a later date if you would
      like.
    </P>

    <P className="bold">Oral Testosterone (Oral Tablet)</P>
    <P className="mb12">
      If you are not concerned with potential suppression of hormones implicated in fertility and your own testosterone
      production, this option may be best for you. Oral Testosterone is preferred for those who are non-responsive to
      enclomiphene + pregnenolone or already know they prefer TRT.
    </P>

    <P className="bold">Oral Testosterone with Enclomiphene + Pregnenolone (Oral Tablet and Sublingual Tablet)</P>
    <P className="mb12">
      Delivers the benefits of Oral Testosterone while also helping you maintain hormones implicated in fertility and
      your own testosterone production: luteinizing hormone (LH) and follicular stimulating hormone (FSH).
    </P>
  </>
);

type Props = {
  firstTimeChoice?: boolean;
  intake: ImmutableMap<KingIntake>;
};

const KingV2ProductSelectionForm = ({ intake, firstTimeChoice = false }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalNotInState, setOpenModalNotInState] = useState(false);
  const customerState = useAppSelector(selectCustomerState);
  const { values, setFieldValue } = useFormikContext<{
    selected_king_v2_product: string;
  }>();
  const kingV2AvailableStates = intake.get('king_v2_available_states');

  const handleChange = (value: string) => {
    setFieldValue('selected_king_v2_product', value);
  };

  const notAvailableInState = !kingV2AvailableStates.includes(customerState);

  const subProductsConfig = [
    {
      name: KingV2SubProducts.EncloPregnolone,
      price: 9999,
      description:
        "This sublingual tablet stimulates your body's own natural (endogenous) testosterone production, and maintains testicular function/fertility.",
    },
    {
      name: KingV2SubProducts.Trt,
      price: 14999,
      notAvailableInState: notAvailableInState,
      description:
        'This oral tablet provides external (exogenous) native testosterone. It may somewhat suppress testicular function/fertility when taken alone.',
    },
    {
      name: KingV2SubProducts.EncloPregnoloneTrt,
      price: 19999,
      notAvailableInState: notAvailableInState,
      description:
        'This combo which includes Enclomiphene + Pregnenolone is the best of both worlds: endogenous & exogenous testosterone, maintaining testicular function/fertility in most cases.',
    },
  ];

  const ModalBodyNotIsState = () => (
    <>
      <P className="mb12">Oral Testosterone is currently only offered in these states:</P>
      <P className="mb12">{kingV2AvailableStates.join(', ')}.</P>
      <P className="mb12">
        Choose Enclomiphene + Pregnenolone today, and we will notify as soon as Oral Testosterone becomes available in
        your state for a seamless transition.
      </P>
    </>
  );

  return (
    <div className="choose_product">
      <GenericProductSelectionForm
        productName={AvailableProducts.King}
        selectedSubProduct={values.selected_king_v2_product as KingV2SubProducts}
        firstTimeChoice={firstTimeChoice}
        subProductsConfig={subProductsConfig}
        handleChange={handleChange}
        setOpenModalNotInState={setOpenModalNotInState}
      />
      <SetProductDialog
        isOpen={openModal}
        title="Which one is right for me?"
        text={<ModalBodySubscription />}
        onClose={() => setOpenModal(false)}
        className="manage_subscription__cancel_modal align-left"
      />
      <SetProductDialog
        isOpen={openModalNotInState}
        title="Native Testosterone not currently available in your state"
        text={<ModalBodyNotIsState />}
        onClose={() => setOpenModalNotInState(false)}
        className="manage_subscription__cancel_modal align-left"
      />
      <div onClick={() => setOpenModal(true)} className="help_decide">
        Help me decide
      </div>
    </div>
  );
};

export default KingV2ProductSelectionForm;
