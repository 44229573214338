import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { push } from 'connected-react-router/immutable';
import PageHeader from 'app/components/common/PageHeader';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import { apiRequestUserCommand } from 'app/actions/customer';
import {
  selectCurrentIntake,
  selectCurrentIntakeProduct,
  selectCustomer,
  selectFurthestProductStepPath,
} from 'app/selectors/customer';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import GenericBYOL from 'app/components/customer/steps/Payment/Generic/GenericBYOL';
import { LabKitTypes, KingV2SubProducts } from 'app/constants/Products';
import * as selectors from 'app/selectors/customer';
import axios from 'axios';
import { getCSRF } from 'app/api';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import KingCheckoutHelper from 'app/components/customer/steps/Payment/king/KingCheckoutHelper';
import MagicianCheckoutHelper from 'app/components/customer/steps/Payment/magician/MagicianCheckoutHelper';
import DiscountBanner from './Payment/DiscountBanner';

const LabSelectionForm = ({ byolParams, setByolParams, setOwnLabSelected, checkoutHelper }) => {
  const { values } = useFormikContext<{
    include_supplement: boolean;
    use_own_lab: boolean;
    lab_kit_type: LabKitTypes;
    own_lab_file_uploaded: boolean;
    selfPay: boolean;
    daily_strength: string;
    drug_variant: string;
    number_of_doses: 4 | 12 | 8 | null | undefined;
    multimonth_plan: 1 | 3 | 12;
  }>();
  const { use_own_lab } = values;

  useEffect(() => {
    setOwnLabSelected(use_own_lab);
  }, [use_own_lab]);

  const intake = useAppSelector(selectCurrentIntake);
  const oralTrtLabNumber = intake.get('oral_trt_lab_number');

  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, currentProductName));
  const kingSubProductName =
    product?.get('opt_in_choice')?.get('selected_king_v2_product') || KingV2SubProducts.EncloPregnolone;

  const kingV2OralTrt =
    [KingV2SubProducts.Trt, KingV2SubProducts.EncloPregnoloneTrt].includes(kingSubProductName) && oralTrtLabNumber <= 3;

  const [byolFormSubmitted, setByolFormSubmitted] = useState(false);

  return (
    <GenericBYOL
      kingV2OralTrt={kingV2OralTrt}
      priceCalculator={checkoutHelper.priceCalculator(values)}
      intake={intake}
      byolParams={byolParams}
      setByolParams={setByolParams}
      byolFormSubmitted={byolFormSubmitted}
      setByolFormSubmitted={setByolFormSubmitted}
      isLabSelectionStep={true}
    />
  );
};

const LabSelectionStep = () => {
  const dispatch = useAppDispatch();
  const [byolParams, setByolParams] = useState();
  const [ownLabSelected, setOwnLabSelected] = useState(false);

  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, currentProductName));
  const customer = useAppSelector(selectCustomer);
  const intake = useAppSelector(selectCurrentIntake);

  const checkoutHelper = createCheckoutHelper(intake, customer, product, true) as
    | KingCheckoutHelper
    | MagicianCheckoutHelper;

  const initialValues = checkoutHelper.productSpecificInitialValues();

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, currentProductName));

  const uploadLabResults = async (params) => {
    if (params === undefined || !ownLabSelected) return;

    await axios.post(
      '/api/commands',
      {
        ...params,
        user_id: customer.get('id'),
        intake_name: intake.get('name'),
        photo_purpose: `${currentProductName}_onboarding_lab`,
        cmdType: 'upload_own_lab_results',
        type: 'upload_own_lab_results',
      },
      { headers: { 'X-CSRF-Token': getCSRF() } },
    );
  };

  const onSubmit = (params) => {
    uploadLabResults(byolParams)
      .then(() => {
        const action = apiRequestUserCommand({
          cmdType: 'lab_selection_opt_in',
          params: {
            lab_kit_type: params.lab_kit_type,
            product_name: currentProductName,
          },
          context: { onSuccessActionCreator },
        });
        dispatch(action);
      })
      .catch(() => {
        // TBU
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <div className="centered mb24 updated-design payments">
          <DiscountBanner />
          <PageHeader title="Choose Your Labs" className="treatment_plan__header mb24 -edged" />
          <form>
            <LabSelectionForm
              byolParams={byolParams}
              setByolParams={setByolParams}
              setOwnLabSelected={setOwnLabSelected}
              checkoutHelper={checkoutHelper}
            />
          </form>
          <BackNextFooter
            noBack={true}
            onNext={handleSubmit}
            testId="submit-lab-selection"
            horizontalPadding={false}
            nextDisabled={!values.lab_kit_type}
          />
        </div>
      )}
    </Formik>
  );
};

export default LabSelectionStep;
